import { Notification, AppActionType } from './type';
import { action } from 'typesafe-actions';
import { ActionsUnion } from '../helper/type.helper';

export const AppActions = {
  showNotification: (notification: Notification) =>
    action(AppActionType.SHOW_NOTIFICATION, { notification }),
  showNotificationSuccess: (notification: Notification) =>
    action(AppActionType.SHOW_NOTIFICATION_SUCCESS, { notification }),
  deleteNotification: (notificationId: string) =>
    action(AppActionType.DELETE_NOTIFICATION, { notificationId }),
};

export type AppAction = ActionsUnion<typeof AppActions>;
