import { TodoActionType, Todo } from './type';
import { action } from 'typesafe-actions';
import { ActionsUnion } from '../helper/type.helper';
import { ColumnName } from '../surveyList/type';

export const TodoActions = {
  getTodoList: () => action(TodoActionType.TODO_GET_LIST),

  getTodoListSuccess: (items: Todo[]) => action(TodoActionType.TODO_GET_LIST_SUCCESS, { items }),

  getTodoListError: (error: string) => action(TodoActionType.TODO_GET_LIST_ERROR, { error }),

  getTodoListWithFilter: (column?: ColumnName, value?: string) =>
    action(TodoActionType.TODO_GET_LIST_WITH_FILTER, { column, value }),

  getTodoListWithOrder: (column?: ColumnName) =>
    action(TodoActionType.TODO_GET_LIST_WITH_ORDER, { column }),

  getTodoListSearch: (keyword: string) => action(TodoActionType.TODO_GET_LIST_SEARCH, { keyword }),

  addTodoItem: (item: Todo) => action(TodoActionType.TODO_ADD_ITEM, { item }),

  addTodoItemSuccess: (items: Todo[]) => action(TodoActionType.TODO_ADD_ITEM_SUCCESS, { items }),

  addTodoItemError: (error: string) => action(TodoActionType.TODO_ADD_ITEM_ERROR, { error }),

  selectedTodoItemsChange: (selectedItemsId: number[]) =>
    action(TodoActionType.TODO_SELECTED_ITEMS_CHANGE, { selectedItems: selectedItemsId }),
};

export type TodoAction = ActionsUnion<typeof TodoActions>;
