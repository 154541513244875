import { Reducer } from 'typesafe-actions';
import { defaultLocale, localeOptions } from '../../constants/defaultValues';
import { SettingsAction } from './action';
import { SettingsActionType } from './type';
import { Languages } from '../../lang/index';

export type SettingsState = {
  currentLanguage: Languages;
};

const lastLanguage = localStorage.getItem('currentLanguage');

const initialState: SettingsState = {
  currentLanguage:
    lastLanguage && localeOptions.filter(x => x.id === lastLanguage).length > 0
      ? (lastLanguage as Languages)
      : defaultLocale,
};

export const settingsReducer: Reducer<SettingsState, SettingsAction> = (
  state = initialState,
  action,
): SettingsState => {
  switch (action.type) {
    case SettingsActionType.CHANGE_LOCALE:
      return { ...state, ...action.payload };

    default:
      return { ...state };
  }
};
