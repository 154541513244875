export enum TodoActionType {
  TODO_GET_LIST = '[todo]: TODO_GET_LIST',
  TODO_GET_LIST_SUCCESS = '[todo]: TODO_GET_LIST_SUCCESS',
  TODO_GET_LIST_ERROR = '[todo]: TODO_GET_LIST_ERROR',
  TODO_GET_LIST_WITH_FILTER = '[todo]: TODO_GET_LIST_WITH_FILTER',
  TODO_GET_LIST_WITH_ORDER = '[todo]: TODO_GET_LIST_WITH_ORDER',
  TODO_GET_LIST_SEARCH = '[todo]: TODO_GET_LIST_SEARCH',
  TODO_ADD_ITEM = '[todo]: TODO_ADD_ITEM',
  TODO_ADD_ITEM_SUCCESS = '[todo]: TODO_ADD_ITEM_SUCCESS',
  TODO_ADD_ITEM_ERROR = '[todo]: TODO_ADD_ITEM_ERROR',
  TODO_SELECTED_ITEMS_CHANGE = '[todo]: TODO_SELECTED_ITEMS_CHANGE',
}

export interface Todo {
  id?: number;
  title: string;
  detail: string;
  category: string;
  createDate?: string;
  status: string;
  label: string;
  labelColor: string;
}
