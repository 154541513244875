import { userReducer } from './user/reducer';
import { combineEpics } from 'redux-observable';
import { combineReducers } from 'redux';
import { authReducer } from './auth/reducer';
import { settingsReducer } from './settings/reducer';
import { surveyListReducer } from './surveyList/reducer';
import { surveyDetailReducer } from './surveyDetail/reducer';
import { surveyListEpics } from './surveyList/epic';
import { surveyDetailEpics } from './surveyDetail/epic';
import { menuReducer } from './menu/reducer';
import { todoReducer } from './todo/reducer';
import { todoEpics } from './todo/epic';
import { chatReducer } from './chat/reducer';
import { chatEpics } from './chat/epic';
import { appEpics } from './app/epic';
import { appReducer } from './app/reducer';
import { userEpics } from './user/epic';

// Pass epics.
// Actions will run through reducers first and then epics.
export const rootEpic = combineEpics(
  ...appEpics,
  ...surveyListEpics,
  ...surveyDetailEpics,
  ...todoEpics,
  ...chatEpics,
  ...userEpics,
);

// Pass reducers.
export const rootReducer = combineReducers({
  appState: appReducer,
  authState: authReducer,
  settingsState: settingsReducer,
  surveyListState: surveyListReducer,
  todoState: todoReducer,
  surveyDetailState: surveyDetailReducer,
  menuState: menuReducer,
  chatState: chatReducer,
  userState: userReducer,
});
