export const AxiosUtils = {
  getErrorMessage: (error: any): string => {
    if (error.response) {
      if (error.response.statusText) return error.response.statusText;
      if (error.response.data.message) return error.response.data.message;
    } else {
      if (error.message) return error.message;
    }

    return 'Unknown error !';
  },
};
