export enum ChatActionType {
  CHAT_GET_CONTACTS = '[chat]: CHAT_GET_CONTACTS',
  CHAT_GET_CONTACTS_SUCCESS = '[chat]: CHAT_GET_CONTACTS_SUCCESS',
  CHAT_GET_CONTACTS_ERROR = '[chat]: CHAT_GET_CONTACTS_ERROR',
  CHAT_GET_CONVERSATIONS = '[chat]: CHAT_GET_CONVERSATIONS',
  CHAT_GET_CONVERSATIONS_SUCCESS = '[chat]: CHAT_GET_CONVERSATIONS_SUCCESS',
  CHAT_GET_CONVERSATIONS_ERROR = '[chat]: CHAT_GET_CONVERSATIONS_ERROR',
  CHAT_ADD_MESSAGE_TO_CONVERSATION = '[chat]: CHAT_ADD_MESSAGE_TO_CONVERSATION',
  CHAT_CREATE_CONVERSATION = '[chat]: CHAT_CREATE_CONVERSATION',
  CHAT_SEARCH_CONTACT = '[chat]: CHAT_SEARCH_CONTACT',
  CHAT_CHANGE_CONVERSATION = '[chat]: CHAT_CHANGE_CONVERSATION',
}

export interface ChatContact {
  id: number;
  name: string;
  thumb: string;
  lastSeenDate: string;
}

export interface ChatConversation {
  id: number;
  users: number[];
  lastMessageTime: string;
  messages: ChatMessage[];
}

export interface ChatMessage {
  sender: number;
  time: string;
  text: string;
}
