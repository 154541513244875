import { Reducer } from 'typesafe-actions';
import { ListState } from '../surveyList/type';
import { TodoActionType, Todo } from './type';
import { TodoAction } from './actions';

const initialState: ListState<Todo> = {
  allItems: [],
  items: [],
  error: '',
  filter: null,
  searchKeyword: '',
  loading: false,
  labels: [
    { label: 'EDUCATION', color: 'secondary' },
    { label: 'NEW FRAMEWORK', color: 'primary' },
    { label: 'PERSONAL', color: 'info' },
  ],
  orderColumns: [
    { column: 'title', label: 'Title' },
    { column: 'category', label: 'Category' },
    { column: 'status', label: 'Status' },
    { column: 'label', label: 'Label' },
  ],
  categories: ['Flexbox', 'Sass', 'React'],
  selectedItemsId: [],
};

export const todoReducer: Reducer<ListState<Todo>, TodoAction> = (
  state = initialState,
  action,
): ListState<Todo> => {
  switch (action.type) {
    case TodoActionType.TODO_GET_LIST:
      return { ...state, loading: true };

    case TodoActionType.TODO_GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        allItems: action.payload.items,
        items: action.payload.items,
      };

    case TodoActionType.TODO_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.error };

    case TodoActionType.TODO_GET_LIST_WITH_FILTER:
      if (!action.payload.column || !action.payload.value) {
        return { ...state, loading: false, items: state.allItems, filter: null };
      } else {
        const filteredItems = state.allItems.filter(
          item => item[action.payload.column!] === action.payload.value,
        );
        return {
          ...state,
          loading: false,
          items: filteredItems,
          filter: {
            column: action.payload.column,
            value: action.payload.value,
          },
        };
      }

    case TodoActionType.TODO_GET_LIST_WITH_ORDER:
      if (!action.payload.column) {
        return { ...state, loading: false, items: state.items, orderColumn: null };
      } else {
        const sortedItems = state.items.sort((a, b) => {
          if (a[action.payload.column!] < b[action.payload.column!]) return -1;
          else if (a[action.payload.column!] > b[action.payload.column!]) return 1;
          return 0;
        });
        return {
          ...state,
          loading: false,
          items: sortedItems,
          orderColumn: state.orderColumns.find(x => x.column === action.payload.column),
        };
      }

    case TodoActionType.TODO_GET_LIST_SEARCH:
      if (action.payload.keyword === '') {
        return { ...state, items: state.allItems };
      } else {
        const keyword = action.payload.keyword.toLowerCase();
        const searchItems = state.allItems.filter(
          item =>
            item.title.toLowerCase().indexOf(keyword) > -1 ||
            item.detail.toLowerCase().indexOf(keyword) > -1 ||
            item.status.toLowerCase().indexOf(keyword) > -1 ||
            item.category.toLowerCase().indexOf(keyword) > -1 ||
            item.label.toLowerCase().indexOf(keyword) > -1,
        );
        return {
          ...state,
          loading: false,
          items: searchItems,
          searchKeyword: action.payload.keyword,
        };
      }

    case TodoActionType.TODO_ADD_ITEM:
      return { ...state, loading: true };

    case TodoActionType.TODO_ADD_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        allItems: action.payload.items,
        items: action.payload.items,
      };

    case TodoActionType.TODO_ADD_ITEM_ERROR:
      return { ...state, loading: false, error: action.payload.error };

    case TodoActionType.TODO_SELECTED_ITEMS_CHANGE:
      return { ...state, loading: false, selectedItemsId: action.payload.selectedItems };
    default:
      return { ...state };
  }
};
