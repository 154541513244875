import { IUser } from '../models/user';
import { HttpService } from '../services/http.service';

export const AuthUtils = {
  setToken: (token: string) => {
    localStorage.setItem('authToken', token);

    // initialize axios
    HttpService.initializeAxios();
  },

  getToken: () => {
    return localStorage.getItem('authToken');
  },

  // setUser: (user: IUser | null) => {
  //   if (user) {
  //     const userData = JSON.stringify(user);
  //     localStorage.setItem('user', userData);
  //   } else {
  //     localStorage.removeItem('user');
  //   }
  // },

  getUser: (): IUser | null => {
    const userData = localStorage.getItem('user');
    return !!userData ? JSON.parse(userData) : null;
  },

  saveLoginInfo: (token: string, user: IUser) => {
    // token
    AuthUtils.setToken(token);

    // user
    const userData = JSON.stringify(user);
    localStorage.setItem('user', userData);
  },

  deleteLoginInfo: () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
  },
};
