import { IUserSettings } from './../../api/models/user-settings';
import { action } from 'typesafe-actions';
import { ActionsUnion } from '../helper/type.helper';

export enum UserActionType {
  SAVE_SETTINGS = '[user-settings]: SAVE_SETTINGS',

  LOAD_SETTINGS = '[user-settings]: LOAD_SETTINGS',
  LOAD_SETTINGS_SUCCESS = '[user-settings]: LOAD_SETTINGS_SUCCESS',
  LOAD_SETTINGS_ERROR = '[user-settings]: LOAD_SETTINGS_ERROR',

  NULL = '[user-settings]: NULL',
}

export const UserActions = {
  saveSettings: (id: string, settings: IUserSettings) => action(UserActionType.SAVE_SETTINGS, { id, settings }),

  loadSettings: (id: string) => action(UserActionType.LOAD_SETTINGS, { id }),
  loadSettingsSuccess: (settings: IUserSettings) => action(UserActionType.LOAD_SETTINGS_SUCCESS, { settings }),
  loadSettingsError: (message: string) => action(UserActionType.LOAD_SETTINGS_ERROR, { message }),

  null: (message: string) => action(UserActionType.NULL, { message }),
};

export type UserActionFuncType = ActionsUnion<typeof UserActions>;
