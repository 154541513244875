import { Reducer } from 'typesafe-actions';
import { ChatActionType, ChatContact, ChatConversation } from './type';
import { ChatAction } from './actions';

type ChatState = {
  allContacts: ChatContact[];
  contacts: ChatContact[];
  conversations: ChatConversation[];
  error: string;
  searchKeyword: string;
  loadingContacts: boolean;
  loadingConversations: boolean;
  currentUser?: ChatContact;
  selectedUser?: ChatContact;
  selectedUserId?: number;
};

const initialState: ChatState = {
  allContacts: [],
  contacts: [],
  conversations: [],
  error: '',
  searchKeyword: '',
  loadingContacts: false,
  loadingConversations: false,
};

export const chatReducer: Reducer<ChatState, ChatAction> = (
  state = initialState,
  action,
): ChatState => {
  switch (action.type) {
    case ChatActionType.CHAT_GET_CONTACTS:
      return { ...state, loadingContacts: true };

    case ChatActionType.CHAT_GET_CONTACTS_SUCCESS:
      return {
        ...state,
        loadingContacts: false,
        allContacts: action.payload.contacts,
        contacts: action.payload.contacts,
        currentUser: action.payload.currentUser,
      };

    case ChatActionType.CHAT_GET_CONTACTS_ERROR:
      return { ...state, loadingContacts: false, error: action.payload.error };

    case ChatActionType.CHAT_GET_CONVERSATIONS:
      return { ...state, loadingConversations: true };

    case ChatActionType.CHAT_GET_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loadingConversations: false,
        conversations: action.payload.conversations,
        selectedUserId: action.payload.selectedUserId,
      };

    case ChatActionType.CHAT_GET_CONVERSATIONS_ERROR:
      return { ...state, loadingConversations: false, error: action.payload.error };

    case ChatActionType.CHAT_CHANGE_CONVERSATION:
      return {
        ...state,
        selectedUser: state.allContacts.find(x => x.id === action.payload.userId),
      };

    case ChatActionType.CHAT_ADD_MESSAGE_TO_CONVERSATION:
      return { ...state };

    case ChatActionType.CHAT_CREATE_CONVERSATION:
      return { ...state };

    case ChatActionType.CHAT_SEARCH_CONTACT:
      if (action.payload.keyword === '') {
        return { ...state, contacts: state.allContacts };
      } else {
        const keyword = action.payload.keyword.toLowerCase();
        const searchedContacts = state.allContacts.filter(
          item => item.name.toLowerCase().indexOf(keyword) > -1,
        );
        return { ...state, contacts: searchedContacts };
      }

    default:
      return { ...state };
  }
};
