import { action } from 'typesafe-actions';
import { ActionsUnion } from '../helper/type.helper';
import { SurveyDetailActionType, SurveyDetail } from './type';

export const SurveyDetailActions = {
  getSurveyDetail: (surveyId: number) =>
    action(SurveyDetailActionType.SURVEY_GET_DETAILS, { surveyId }),
  getSurveyDetailSuccess: (item: SurveyDetail) =>
    action(SurveyDetailActionType.SURVEY_GET_DETAILS_SUCCESS, { item }),

  getSurveyDetailError: (error: string) =>
    action(SurveyDetailActionType.SURVEY_GET_DETAILS_ERROR, { error }),

  deleteSurveyQuestion: (questionId: number, surveyDetail: SurveyDetail) =>
    action(SurveyDetailActionType.SURVEY_DELETE_QUESTION, { questionId, surveyDetail }),

  saveSurveyDetail: (surveyDetail: SurveyDetail) =>
    action(SurveyDetailActionType.SURVEY_SAVE, { surveyDetail }),
};

export type SurveyDetailAction = ActionsUnion<typeof SurveyDetailActions>;
