export enum AppActionType {
  SHOW_NOTIFICATION = '[app]: SHOW_NOTIFICATION',
  SHOW_NOTIFICATION_SUCCESS = '[app]: SHOW_NOTIFICATION_SUCCESS',
  DELETE_NOTIFICATION = '[app]: DELETE_NOTIFICATION',
}

export type NotificationType =
  | 'change'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error';

export interface Notification {
  id?: string;
  type: NotificationType;
  message: string;
  title?: string;
  timeOut?: number;
  onClick?: () => void;
  onRequestHide?: () => void;
  customClassName?: string;
  priority?: boolean;
}
