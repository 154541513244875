import { BaseService } from './base.service';
import { ISalesCampaign } from './../../models/salesCampaign';
import Axios from 'axios';
import { IFile } from '../../models/file';
import { AxiosUtils } from './../../utils/axios.util';

export class SalesCampaignService extends BaseService {
  uploadExcelFile(processId: string, data: FormData) {
    return new Promise<IFile[]>(async (resolve, reject) => {
      try {
        const response = await Axios.post<{ message: string; files: IFile[] }>(
          '/sales-campaign/file/' + processId,
          data,
          {
            onUploadProgress: ProgressEvent => {
              const loaded = (ProgressEvent.loaded / ProgressEvent.total) * 100;
              console.log('Progress:', loaded);
            },
          },
        );
        resolve(response.data.files);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  waitingExcelFileList(processId: string) {
    return new Promise<IFile[]>(async (resolve, reject) => {
      try {
        const response = await Axios.get<{ files: IFile[] }>(
          '/sales-campaign/waiting-excel-file-list/' + processId,
          {},
        );
        resolve(response.data.files);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  deleteWaitingExcelFile(id: string) {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        await Axios.get<{ message: string }>('/sales-campaign/delete-waiting-excel-file/' + id, {});
        resolve(true);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  getCampaignFromFile(fileId: string) {
    return new Promise<ISalesCampaign>(async (resolve, reject) => {
      try {
        const response = await Axios.get<{ campaign: ISalesCampaign }>(
          '/sales-campaign/get-campaign-from-file/' + fileId,
          {},
        );
        resolve(response.data.campaign);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  createCampaignFromFile(fileId: string, ref: string, startDate: Date, endDate: Date, description: string) {
    return new Promise<ISalesCampaign>(async (resolve, reject) => {
      try {
        const response = await Axios.post<{ campaign: ISalesCampaign }>(
          '/sales-campaign/create-campaign-from-file/' + fileId,
          {
            ref,
            startDate,
            endDate,
            description,
          },
        );
        resolve(response.data.campaign);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  createCampaign(ref: string, startDate: Date, endDate: Date, description: string) {
    return new Promise<ISalesCampaign>(async (resolve, reject) => {
      try {
        const response = await Axios.post<{ campaign: ISalesCampaign }>('/sales-campaign/create-campaign/', {
          ref,
          startDate,
          endDate,
          description,
        });
        resolve(response.data.campaign);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  list(pageSize: number, currentPage: number, orderBy: string, filter: { campaignOption: string; filterTerm: string }) {
    return new Promise<{ campaigns: ISalesCampaign[]; totalPage: number; totalItem: number }>(
      async (resolve, reject) => {
        try {
          const response = await Axios.post<{ campaigns: ISalesCampaign[]; totalPage: number; totalItem: number }>(
            '/sales-campaign/list/',
            { pageSize, currentPage, orderBy, filter },
          );
          resolve({
            campaigns: response.data.campaigns,
            totalPage: response.data.totalPage,
            totalItem: response.data.totalItem,
          });
        } catch (error) {
          const message = AxiosUtils.getErrorMessage(error);
          reject(message);
        }
      },
    );
  }

  update(id: string, ref: string, startDate: Date | null, endDate: Date | null, description: string) {
    return new Promise<ISalesCampaign>(async (resolve, reject) => {
      try {
        const response = await Axios.post<{ campaign: ISalesCampaign }>('/sales-campaign/update/' + id, {
          ref,
          startDate,
          endDate,
          description,
        });
        resolve(response.data.campaign);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  delete(id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        await Axios.get('/sales-campaign/delete/' + id, {});
        resolve();
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  getExcelFileLink(id: string) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const response = await Axios.get<{ token: string }>('/sales-campaign/get-excel-file-link/' + id, {});
        resolve(response.data.token);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }
}
