export enum SurveyDetailActionType {
  SURVEY_GET_DETAILS = '[survey detail] SURVEY_GET_DETAILS',
  SURVEY_GET_DETAILS_SUCCESS = '[survey detail] SURVEY_GET_DETAILS_SUCCESS',
  SURVEY_GET_DETAILS_ERROR = '[survey detail] SURVEY_GET_DETAILS_ERROR',
  SURVEY_DELETE_QUESTION = '[survey detail] SURVEY_DELETE_QUESTION',
  SURVEY_SAVE = '[survey detail] SURVEY_SAVE',
}

export interface SurveyDetail {
  id: number;
  title: string;
  detail: string;
  category: string;
  createDate: string;
  status: string;
  label: string;
  labelColor: string;
  questions: Question[];
}

export interface Question {
  id: number;
  title?: string;
  question?: string;
  answerType?: number;
  answers?: Answer[];
}

export interface Answer {
  id: number;
  label: string;
}
