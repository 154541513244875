import { SurveyDetailActionType, SurveyDetail } from './type';
import { Reducer } from 'typesafe-actions';
import { SurveyDetailAction } from './action';

export type SurveyDetailState = {
  surveyDetail: SurveyDetail | null;
  loading: boolean;
  error: string | null;
};

const initialState: SurveyDetailState = {
  surveyDetail: null,
  loading: true,
  error: null,
};

export const surveyDetailReducer: Reducer<SurveyDetailState, SurveyDetailAction> = (
  state = initialState,
  action,
): SurveyDetailState => {
  switch (action.type) {
    case SurveyDetailActionType.SURVEY_GET_DETAILS:
      return { ...state, loading: true };

    case SurveyDetailActionType.SURVEY_GET_DETAILS_SUCCESS:
      return { ...state, loading: false, surveyDetail: action.payload.item };

    case SurveyDetailActionType.SURVEY_GET_DETAILS_ERROR:
      return { ...state, loading: false, error: action.payload.error };

    case SurveyDetailActionType.SURVEY_DELETE_QUESTION:
      return { ...state, loading: true };

    case SurveyDetailActionType.SURVEY_SAVE:
      return { ...state, loading: false, surveyDetail: action.payload.surveyDetail };

    default:
      return { ...state };
  }
};
