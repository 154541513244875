import { defaultDirection, themeRadiusStorageKey } from '../constants/defaultValues';
import { v4 as uuidv4 } from 'uuid';

export const getNewId = (): string => {
  return uuidv4();
};

export const mapOrder = <T>(array: T[], order: any, key: string | number): T[] => {
  array.sort(function (a: { [x: string]: any }, b: { [x: string]: any }) {
    var A = a[key],
      B = b[key];
    if (order.indexOf(A + '') > order.indexOf(B + '')) {
      return 1;
    } else {
      return -1;
    }
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; //January is 0!
  let date = '';
  var yyyy = today.getFullYear();

  date = dd.toString().padStart(2, '0') + '.';
  date += mm.toString().padStart(2, '0') + '.';
  date += yyyy;

  return date;
};

export const getCurrentTime = () => {
  const now = new Date();
  return now.getHours() + ':' + now.getMinutes();
};

export const getDirection = () => {
  let direction = defaultDirection;
  if (localStorage.getItem('direction')) {
    const localValue = localStorage.getItem('direction');
    if (localValue === 'rtl' || localValue === 'ltr') {
      direction = localValue;
    }
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};

export const setDirection = (localValue: string) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  localStorage.setItem('direction', direction);
};

export const getDefaultThemeRadiusStorageKey = () => {
  return (localStorage.getItem(themeRadiusStorageKey) as 'rounded' | 'flat') || 'rounded';
};
