import { Epic } from 'redux-observable';
import { filter, switchMap } from 'rxjs/operators';
import { getDateWithFormat } from '../../helpers/Utils';
import todoData from '../../data/todos.json';
import { isOfType } from 'typesafe-actions';
import { TodoActionType, Todo } from './type';
import { TodoActions, TodoAction } from './actions';

const getTodoList: Epic<TodoAction> = actions$ =>
  actions$.pipe(
    filter(isOfType(TodoActionType.TODO_GET_LIST)),
    switchMap(async action => {
      return await new Promise<Todo[]>((success, fail) => {
        setTimeout(() => {
          success(todoData.data);
        }, 1000);
      })
        .then(response => TodoActions.getTodoListSuccess(response))
        .catch(error => TodoActions.getTodoListError(error));
    }),
  );

const addTodoList: Epic<TodoAction> = actions$ =>
  actions$.pipe(
    filter(isOfType(TodoActionType.TODO_ADD_ITEM)),
    switchMap(async action => {
      const { item } = action.payload;
      let items = todoData.data as Todo[];
      item.id = items.length + 1;
      item.createDate = getDateWithFormat();
      items.splice(0, 0, item);

      console.log(item);

      return await new Promise<Todo[]>((success, fail) => {
        setTimeout(() => {
          success(items);
        }, 1000);
      })
        .then(response => TodoActions.addTodoItemSuccess(response))
        .catch(error => TodoActions.addTodoItemError(error));
    }),
  );

export const todoEpics = [getTodoList, addTodoList];
