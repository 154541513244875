import { getCurrentTime } from '../../helpers/Utils';
import contactsData from '../../data/chat.contacts.json';
import conversationsData from '../../data/chat.conversations.json';
import { filter, switchMap } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import { ChatAction, ChatActions } from './actions';
import { ChatActionType, ChatContact, ChatConversation } from './type';
import { isOfType } from 'typesafe-actions';

const getContacts: Epic<ChatAction> = actions$ =>
  actions$.pipe(
    filter(isOfType(ChatActionType.CHAT_GET_CONTACTS)),
    switchMap(async action => {
      const contacts = contactsData.data as ChatContact[];
      const currentUser = contacts[0];
      return await new Promise<{ contacts: ChatContact[]; currentUser: ChatContact }>(
        (success, fail) => {
          setTimeout(() => {
            success({ contacts, currentUser });
          }, 2000);
        },
      )
        .then(response => ChatActions.getContactsSuccess(response.contacts, response.currentUser))
        .catch(error => ChatActions.getContactsError(error));
    }),
  );

const getConversations: Epic<ChatAction> = actions$ =>
  actions$.pipe(
    filter(isOfType(ChatActionType.CHAT_GET_CONVERSATIONS)),
    switchMap(async action => {
      const { userId } = action.payload;
      let conversations = conversationsData.data as ChatConversation[];
      conversations = conversations.filter(x => x.users.includes(userId));
      const selectedUserId = conversations[0].users.find(x => x !== userId)!;

      return await new Promise<{ conversations: ChatConversation[]; selectedUserId: number }>(
        (success, fail) => {
          setTimeout(() => {
            success({ conversations, selectedUserId });
          }, 1000);
        },
      )
        .then(response =>
          ChatActions.getConversationsSuccess(response.conversations, response.selectedUserId),
        )
        .catch(error => ChatActions.getConversationsError(error));
    }),
  );

const addMessageToConversation: Epic<ChatAction> = actions$ =>
  actions$.pipe(
    filter(isOfType(ChatActionType.CHAT_ADD_MESSAGE_TO_CONVERSATION)),
    switchMap(async action => {
      const { currentUserId, selectedUserId, message, allConversations } = action.payload;

      let conversation = allConversations.find(
        x => x.users.includes(currentUserId) && x.users.includes(selectedUserId),
      );
      const time = getCurrentTime();
      if (conversation) {
        conversation.messages.push({
          sender: currentUserId,
          time: time,
          text: message,
        });
        conversation.lastMessageTime = time;
        let conversations = allConversations.filter(x => x.id !== conversation!.id);
        conversations.splice(0, 0, conversation);

        return await new Promise<{ conversations: ChatConversation[]; selectedUserId: number }>(
          (success, fail) => {
            setTimeout(() => {
              success({ conversations, selectedUserId });
            }, 500);
          },
        )
          .then(response =>
            ChatActions.getConversationsSuccess(response.conversations, response.selectedUserId),
          )
          .catch(error => ChatActions.getConversationsError(error));
      } else {
        return ChatActions.getConversationsError('conversations not found');
      }
    }),
  );

const createNewConversation: Epic<ChatAction> = actions$ =>
  actions$.pipe(
    filter(isOfType(ChatActionType.CHAT_CREATE_CONVERSATION)),
    switchMap(async action => {
      const { currentUserId, selectedUserId, allConversations } = action.payload;

      let conversation: ChatConversation = {
        id: allConversations.length + 1,
        users: [currentUserId, selectedUserId],
        lastMessageTime: '-',
        messages: [],
      };
      allConversations.splice(0, 0, conversation);

      return await new Promise<{ conversations: ChatConversation[]; selectedUserId: number }>(
        (success, fail) => {
          setTimeout(() => {
            success({ conversations: allConversations, selectedUserId });
          }, 500);
        },
      )
        .then(response =>
          ChatActions.getConversationsSuccess(response.conversations, response.selectedUserId),
        )
        .catch(error => ChatActions.getConversationsError(error));
    }),
  );

export const chatEpics = [
  getContacts,
  getConversations,
  addMessageToConversation,
  createNewConversation,
];
