import { defaultMenuType, subHiddenBreakpoint, menuHiddenBreakpoint } from '../../constants/defaultValues';
import { Reducer } from 'typesafe-actions';
import { MenuAction } from './actions';

export type MenuState = {
  containerClassnames: string;
  subHiddenBreakpoint: number;
  menuHiddenBreakpoint: number;
  menuClickCount: number;
  selectedMenuHasSubItems: boolean; //if you use menu-sub-hidden as default menu type, set value of this variable to false
};

const initialState: MenuState = {
  containerClassnames: defaultMenuType,
  subHiddenBreakpoint,
  menuHiddenBreakpoint,
  menuClickCount: 0,
  selectedMenuHasSubItems: false, // defaultMenuType === 'menu-default', //if you use menu-sub-hidden as default menu type, set value of this variable to false
};

export const menuReducer: Reducer<MenuState, MenuAction> = (state = initialState, action): MenuState => {
  switch (action.type) {
    // case MenuActionType.MENU_CHANGE_HAS_SUB_ITEM_STATUS:
    //   return { ...state, selectedMenuHasSubItems: action.payload.selectedMenuHasSubItems };

    // case MenuActionType.MENU_SET_CLASSNAMES:
    //   return {
    //     ...state,
    //     containerClassnames: action.payload.containerClassnames,
    //     menuClickCount: action.payload.menuClickCount,
    //   };

    // case MenuActionType.MENU_CLICK_MOBILE_MENU:
    //   return {
    //     ...state,
    //     containerClassnames: action.payload.containerClassnames,
    //     menuClickCount: action.payload.menuClickCount,
    //   };

    // case MenuActionType.MENU_CONTAINER_ADD_CLASSNAME:
    //   return { ...state, containerClassnames: action.payload.containerClassnames };

    // case MenuActionType.MENU_CHANGE_DEFAULT_CLASSES:
    //   return { ...state, containerClassnames: action.payload.containerClassnames };

    default:
      return { ...state };
  }
};
