import { Reducer } from 'typesafe-actions';
import { IUserSettings } from './../../api/models/user-settings';
import { UserUtils } from '../../api/utils/user.util';
import { UserActionFuncType, UserActionType } from './action';

export type UserStateType = {
  settings: IUserSettings | null;
};

const initialState: UserStateType = {
  settings: UserUtils.getSettings(),
};

export const userReducer: Reducer<UserStateType, UserActionFuncType> = (
  state = initialState,
  action,
): UserStateType => {
  switch (action.type) {
    case UserActionType.SAVE_SETTINGS: {
      const { settings } = action.payload;
      return { ...state, settings };
    }

    // case UserActionType.LOAD_SETTINGS: {    }

    case UserActionType.LOAD_SETTINGS_SUCCESS: {
      const { settings } = action.payload;
      return { ...state, settings };
    }

    case UserActionType.LOAD_SETTINGS_ERROR: {
      const settings: IUserSettings = {
        listPageSize: 30,
      };
      return { ...state, settings };
    }

    default:
      return { ...state };
  }
};
