import React, { createContext, FC, useContext } from 'react';
import { AuthService } from './services/auth.service';
import { useDispatch } from 'react-redux';
import { SalesCampaignService } from './services/sales-campaign.service';

type Services = {
  Auth: AuthService;
  SalesCampaignService: SalesCampaignService;
};

const ServiceContext = createContext<Services>({} as Services);

export const ServiceProvider: FC = props => {
  const dispatch = useDispatch();

  const services: Services = {
    Auth: new AuthService(dispatch),
    SalesCampaignService: new SalesCampaignService(dispatch)
  };

  return <ServiceContext.Provider value={services}>{props.children}</ServiceContext.Provider>;
};

export const useServices = () => useContext(ServiceContext);

