import { AxiosUtils } from './../../utils/axios.util';
import Axios from 'axios';
import { BaseService } from './base.service';
import { IUser } from '../../models/user';
import { AuthActions } from '../../../redux/auth/action';
import { AuthUtils } from '../../utils/auth.util';

export class AuthService extends BaseService {
  login(username: string, password: string) {
    return new Promise<IUser>(async (resolve, reject) => {
      try {
        const response = await Axios.post<{ user: IUser; token: string }>('/auth/login', { username, password });
        const { user, token } = response.data;

        AuthUtils.saveLoginInfo(token, user);

        this.dispatch(AuthActions.authenticate(user));
        resolve(user);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  changePassword(currentPassword: string, newPassword: string) {
    return new Promise<string>(async (resolve, reject) => {
      try {
        const response = await Axios.post<{ message: string }>('/auth/change-password', {
          currentPassword,
          newPassword,
        });
        resolve(response.data.message);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  register(username: string, password: string) {
    return new Promise<IUser>(async (resolve, reject) => {
      try {
        const response = await Axios.post<{ user: IUser }>('/auth/register', {
          username,
          password,
        });
        const { user } = response.data;

        this.dispatch(AuthActions.authenticate(user));
        resolve(user);
      } catch (error) {
        const message = AxiosUtils.getErrorMessage(error);
        reject(message);
      }
    });
  }

  logout() {
    AuthUtils.deleteLoginInfo();
    this.dispatch(AuthActions.logout());
  }
}
