export enum SurveyListActionType {
  SURVEY_LIST_GET_LIST = '[survey list]: SURVEY_LIST_GET_LIST',
  SURVEY_LIST_GET_LIST_SUCCESS = '[survey list]: SURVEY_LIST_GET_LIST_SUCCESS',
  SURVEY_LIST_GET_LIST_ERROR = '[survey list]: SURVEY_LIST_GET_LIST_ERROR',
  SURVEY_LIST_GET_LIST_WITH_FILTER = '[survey list]: SURVEY_LIST_GET_LIST_WITH_FILTER',
  SURVEY_LIST_GET_LIST_WITH_ORDER = '[survey list]: SURVEY_LIST_GET_LIST_WITH_ORDER',
  SURVEY_LIST_GET_LIST_SEARCH = '[survey list]: SURVEY_LIST_GET_LIST_SEARCH',
  SURVEY_LIST_ADD_ITEM = '[survey list]: SURVEY_LIST_ADD_ITEM',
  SURVEY_LIST_ADD_ITEM_SUCCESS = '[survey list]: SURVEY_LIST_ADD_ITEM_SUCCESS',
  SURVEY_LIST_ADD_ITEM_ERROR = '[survey list]: SURVEY_LIST_ADD_ITEM_ERROR',
  SURVEY_LIST_SELECTED_ITEMS_CHANGE = '[survey list]: SURVEY_LIST_SELECTED_ITEMS_CHANGE',
}

export interface Survey {
  id?: number;
  title: string;
  category: string;
  createDate?: string;
  status: string;
  label: string;
  labelColor: string;
}

export interface ListState<T> {
  allItems: T[];
  items: T[];
  error: string | null;
  filter: { column: ColumnName; value: string } | null;
  searchKeyword: string;
  orderColumn?: { column: ColumnName; label: string } | null;
  loading: boolean;
  labels: { label: string; color: string }[];
  orderColumns: { column: ColumnName; label: string }[];
  categories: string[];
  selectedItemsId: number[];
}

export type ColumnName = 'title' | 'category' | 'status' | 'label';
