import { action } from 'typesafe-actions';
import { SurveyListActionType, Survey, ColumnName } from './type';
import { ActionsUnion } from '../helper/type.helper';

export const SurveyListActions = {
  getSurveyList: () => action(SurveyListActionType.SURVEY_LIST_GET_LIST),

  getSurveyListSuccess: (items: Survey[]) =>
    action(SurveyListActionType.SURVEY_LIST_GET_LIST_SUCCESS, { items }),

  getSurveyListError: (error: string) =>
    action(SurveyListActionType.SURVEY_LIST_GET_LIST_ERROR, { error }),

  getSurveyListWithFilter: (column?: ColumnName, value?: string) =>
    action(SurveyListActionType.SURVEY_LIST_GET_LIST_WITH_FILTER, { column, value }),

  getSurveyListWithOrder: (column?: ColumnName) =>
    action(SurveyListActionType.SURVEY_LIST_GET_LIST_WITH_ORDER, { column }),

  getSurveyListSearch: (keyword: string) =>
    action(SurveyListActionType.SURVEY_LIST_GET_LIST_SEARCH, { keyword }),

  addSurveyItem: (item: Survey) => action(SurveyListActionType.SURVEY_LIST_ADD_ITEM, { item }),

  addSurveyItemSuccess: (items: Survey[]) =>
    action(SurveyListActionType.SURVEY_LIST_ADD_ITEM_SUCCESS, { items }),

  addSurveyItemError: (error: string) =>
    action(SurveyListActionType.SURVEY_LIST_ADD_ITEM_ERROR, { error }),

  selectedSurveyItemsChange: (selectedItems: number[]) =>
    action(SurveyListActionType.SURVEY_LIST_SELECTED_ITEMS_CHANGE, { selectedItems }),
};
export type SurveyListAction = ActionsUnion<typeof SurveyListActions>;
