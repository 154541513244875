import { IUserSettings } from './../models/user-settings';

export const UserUtils = {
  getSettings: (): IUserSettings | null => {
    const settingsData = localStorage.getItem('userSettings');
    return !!settingsData ? JSON.parse(settingsData) : null;
  },

  saveSettings: (settings: IUserSettings) => {
    const settingsData = JSON.stringify(settings);
    localStorage.setItem('userSettings', settingsData);
  },
};
