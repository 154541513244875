import { IUserSettings } from './../../api/models/user-settings';
import { UserActionType, UserActionFuncType, UserActions } from './action';
import { Epic } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, switchMap } from 'rxjs/operators';
import Axios from 'axios';

const updateSettings: Epic<UserActionFuncType> = action$ =>
  action$.pipe(
    filter(isOfType(UserActionType.SAVE_SETTINGS)),
    switchMap(async action => {
      const { id, settings } = action.payload;

      try {
        await Axios.post('/user-settings/update/' + id, { ...settings });
        return UserActions.null('saved');
      } catch (error) {
        return UserActions.null(error.message);
      }
    }),
  );

const loadSettings: Epic<UserActionFuncType> = action$ =>
  action$.pipe(
    filter(isOfType(UserActionType.LOAD_SETTINGS)),
    switchMap(async action => {
      const { id } = action.payload;

      try {
        const response = await Axios.get<{ settings: IUserSettings }>('/user-settings/get/' + id, {});
        return UserActions.loadSettingsSuccess(response.data.settings);
      } catch (error) {
        return UserActions.loadSettingsError(error.message);
      }
    }),
  );

export const userEpics = [updateSettings, loadSettings];
