import { action } from 'typesafe-actions';
import { ChatActionType, ChatContact, ChatConversation } from './type';
import { ActionsUnion } from '../helper/type.helper';

export const ChatActions = {
  getContacts: () => action(ChatActionType.CHAT_GET_CONTACTS),

  getContactsSuccess: (contacts: ChatContact[], currentUser: ChatContact) =>
    action(ChatActionType.CHAT_GET_CONTACTS_SUCCESS, { contacts, currentUser }),

  getContactsError: (error: string) => action(ChatActionType.CHAT_GET_CONTACTS_ERROR, { error }),

  getConversations: (userId: number) => action(ChatActionType.CHAT_GET_CONVERSATIONS, { userId }),

  getConversationsSuccess: (conversations: ChatConversation[], selectedUserId: number) =>
    action(ChatActionType.CHAT_GET_CONVERSATIONS_SUCCESS, { conversations, selectedUserId }),

  getConversationsError: (error: string) =>
    action(ChatActionType.CHAT_GET_CONVERSATIONS_ERROR, { error }),

  addMessageToConversation: (
    currentUserId: number,
    selectedUserId: number,
    message: string,
    allConversations: ChatConversation[],
  ) =>
    action(ChatActionType.CHAT_ADD_MESSAGE_TO_CONVERSATION, {
      currentUserId,
      selectedUserId,
      message,
      allConversations,
    }),

  createConversation: (
    currentUserId: number,
    selectedUserId: number,
    allConversations: ChatConversation[],
  ) =>
    action(ChatActionType.CHAT_CREATE_CONVERSATION, {
      currentUserId,
      selectedUserId,
      allConversations,
    }),

  searchContact: (keyword: string) => action(ChatActionType.CHAT_SEARCH_CONTACT, { keyword }),

  changeConversation: (userId: number) =>
    action(ChatActionType.CHAT_CHANGE_CONVERSATION, { userId }),
};

export type ChatAction = ActionsUnion<typeof ChatActions>;
