import surveyDetailData from '../../data/survey.detail.json';
import { Epic } from 'redux-observable';
import { filter, switchMap } from 'rxjs/operators';
import { SurveyDetailAction, SurveyDetailActions } from './action';
import { isOfType } from 'typesafe-actions';
import { SurveyDetailActionType, SurveyDetail } from './type';

const getSurveyDetail: Epic<SurveyDetailAction> = actions$ =>
  actions$.pipe(
    filter(isOfType(SurveyDetailActionType.SURVEY_GET_DETAILS)),
    switchMap(async action => {
      const surveyDetailList = surveyDetailData.data as SurveyDetail[];
      const surveyDetail = surveyDetailList.find(x => x.id === action.payload.surveyId);
      return await new Promise<SurveyDetail>((resolve, fail) => {
        setTimeout(() => resolve(surveyDetail), 1000);
      })
        .then(response => SurveyDetailActions.getSurveyDetailSuccess(response))
        .catch(error => error);
    }),
  );

const deleteQuestion: Epic<SurveyDetailAction> = actions$ =>
  actions$.pipe(
    filter(isOfType(SurveyDetailActionType.SURVEY_DELETE_QUESTION)),
    switchMap(async action => {
      let { surveyDetail, questionId } = action.payload;
      surveyDetail.questions = surveyDetail.questions.filter(x => x.id !== questionId);

      return await new Promise<SurveyDetail>((success, fail) => {
        success(surveyDetail);
      })
        .then(response => SurveyDetailActions.saveSurveyDetail(response))
        .catch(error => SurveyDetailActions.getSurveyDetailError(error));
    }),
  );

export const surveyDetailEpics = [getSurveyDetail, deleteQuestion];
