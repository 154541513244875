import { getDateWithFormat } from '../../helpers/Utils';
import surveyListData from '../../data/survey.list.json';
import { Epic } from 'redux-observable';
import { filter, switchMap } from 'rxjs/operators';
import { SurveyListActions, SurveyListAction } from './actions';
import { isOfType } from 'typesafe-actions';
import { SurveyListActionType, Survey } from './type';

const getSurveyList: Epic<SurveyListAction> = actions$ =>
  actions$.pipe(
    filter(isOfType(SurveyListActionType.SURVEY_LIST_GET_LIST)),
    switchMap(async action => {
      return await new Promise<Survey[]>((success, fail) => {
        setTimeout(() => {
          success(surveyListData.data);
        }, 1000);
      })
        .then(response => SurveyListActions.getSurveyListSuccess(response))
        .catch(error => SurveyListActions.getSurveyListError(error));
    }),
  );

const addSurveyItem: Epic<SurveyListAction> = actions$ =>
  actions$.pipe(
    filter(isOfType(SurveyListActionType.SURVEY_LIST_ADD_ITEM)),
    switchMap(async action => {
      let { item } = action.payload;
      let items = surveyListData.data as Survey[];

      item.id = items.length + 1;
      item.createDate = getDateWithFormat();
      items.splice(0, 0, item);

      return await new Promise<Survey[]>((success, fail) => {
        setTimeout(() => {
          success(items);
        }, 1000);
      })
        .then(response => SurveyListActions.addSurveyItemSuccess(response))
        .catch(error => SurveyListActions.addSurveyItemError(error));
    }),
  );

export const surveyListEpics = [getSurveyList, addSurveyItem];
